<template>
    <div class="container">
        <div class="row">
            <h1>Showcase</h1>
        </div>

        <div class="row module">
        <div class="module-header">
          <div class="module-title">
            <h3>3D Models</h3>
          </div>
        </div>
        <div class="module-text line-height-adjusted">
          <p>3D Modelle mit three.js (<a href="https://threejs.org" target="_blank">https://threejs.org</a>)</p>
        </div>
        <div>
          <div
            class="custom-btn float-end"
            @click="start3DModelsView"
          >
            Start 
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();

const start3DModelsView = () => {
    router.push("/showcase/3d-models");
}


</script>

<style scoped>
.module {
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 10px 5px 10px;
  margin: 30px;
  column-span: none;
}
.module-header{
  display: flex;
  flex-direction: row;
  align-items: center; 
}
.check-container{
  margin-left: 20px;
}
.module-text{
  line-height: 1.2;
}
p{
  line-height: 1.2;
}
</style>